<template>
  <div v-if="!loading">
    <new-enterprise-qualification
      v-if="isShowNew"
      @completed="findEnterpriseQualification"
    />
    <show-enterprise-qualification
      v-else
      :data="data"
      @completed="findEnterpriseQualification"
    />
  </div>
</template>

<script>

import { findEnterpriseQualification } from '@/api/enterprise_qualification'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'EnterpriseQualification',
  components: {
    NewEnterpriseQualification: () => import('@/views/enterprise_qualifications/New'),
    ShowEnterpriseQualification: () => import('@/views/enterprise_qualifications/Show')
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    isShowNew() {
      return this.data.id === 0 && hasPermission('enterprise_qualifications/create')
    }
  },
  created() {
    this.findEnterpriseQualification()
  },
  methods: {
    findEnterpriseQualification() {
      this.loading = true
      findEnterpriseQualification({ agent_id: this.agentId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>
