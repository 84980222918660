import request from '@/utils/request'

// 查询用户企业资质
export function findEnterpriseQualification(params) {
  return request({
    url: `/enterprise_qualifications`,
    method: 'get',
    params: params
  })
}

// 创建提交企业资质信息
export function createEnterpriseQualification(data) {
  return request({
    url: `/enterprise_qualifications`,
    method: 'post',
    data
  })
}

// 解除企业资质信息
export function dismissEnterpriseQualification(id) {
  return request({
    url: `/enterprise_qualifications/${id}/dismiss`,
    method: 'patch'
  })
}
